import * as React from "react";
import "twin.macro";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import MessageScreen from "../../components/MessageScreen";
import { navigate } from "gatsby";
import SadSmilie from "../../components/SadSmilie";

// markup
/** @ts-ignore */
const TieBreakNoPage = ({ location }) => {
  const [thankyou, setThankyou] = React.useState(false);
  const [nickname, setNickname] = React.useState("");

  function backToMap() {
    navigate("/");
  }

  return (
    <Layout>
      <MessageScreen onClose={backToMap}>
        <MessageScreen.Text>
          <SadSmilie tw="text-secondary text-center block mx-auto max-w-full h-auto" />
        </MessageScreen.Text>
        <MessageScreen.Text tw="mt-3">Leider falsch</MessageScreen.Text>
        <Button tw="mt-4 min-w-30" onClick={backToMap}>
          Zur Karte
        </Button>
      </MessageScreen>
    </Layout>
  );
};

export default TieBreakNoPage;
