import * as React from "react";

export default function SadSmilie({ ...props }) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <circle cx="50" cy="50" r="49.5" stroke="currentColor" />
      <circle cx="33" cy="38" r="7.5" stroke="currentColor" />
      <circle cx="67" cy="38" r="7.5" stroke="currentColor" />
      <path
        d="M49.9996 67.5C41.6155 67.5 35.3545 71.0242 32.8287 74.8513L23.326 69.2955C28.3143 61.2183 38.8759 56.5 49.9996 56.5C61.1233 56.5 71.6849 61.2183 76.6733 69.2955L67.1705 74.8513C64.6447 71.0242 58.3837 67.5 49.9996 67.5Z"
        stroke="currentColor"
      />
    </svg>
  );
}
